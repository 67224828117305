.footer {
  &-container {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
  }

  font-size: 0.6rem;
  text-align: center;
  line-height: 240%;

  transition: 0.3 linear;

  &--white {
    color: #000;
    background-color: #fff;
  }
}
