.health {
  min-height: calc(100vh - (#{$base-font-size} * 0.6 * 2.4));
  padding: $header-height 20px 20px 20px;

  background-color: #fff;
  color: #000;

  &-container {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
  }

  &__header {
    padding: 10px 0;

    &__title {
      padding: 10px 0;
      font-family: "Roboto", "Oxygen", sans-serif;
      color: $olive-drab-3;
      font-size: 1.8rem;
      font-weight: 700;
      text-align: center;
    }

    &__logo {
      padding-bottom: 10px;
      text-align: center;
    }

    &__logo__only {
      width: 150px;
      transition: 1s linear;
      &--hidden {
        opacity: 0;
      }
    }
  }

  &__how-we-do-it {
    padding: 10px 0;
    text-align: center;
    line-height: 140%;

    &__title {
      font-family: "Roboto", "Oxygen", sans-serif;
      font-size: 1.2rem;
      font-weight: 700;
      & span {
        text-transform: uppercase;
        color: $fortior-red;
      }
    }
    &__par {
      padding-bottom: 20px;
    }

    &__figure {
      padding-top: 10px;
      padding-bottom: 30px;
      text-align: center;
    }

    &__img {
      width: 200px;
      transition: 1s linear;
      &--hidden {
        opacity: 0;
      }
    }
  }

  &__animation {
    position: relative;
    width: 100%;
    height: 170px;

    &__frame {
      position: absolute;
      width: 100%;
      height: 100%;

      & p {
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        color: $olive-drab-3;
        font-size: 0.8rem;
        font-weight: 700;
        text-transform: uppercase;
      }

      & svg {
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translateX(-50%);

        color: $olive-drab-3;
      }

      &--1 {
        & p {
          animation: how-p 17s ease-in-out 2s infinite both;
        }

        & svg {
          animation: how-svg 17s ease-in-out 2s infinite both;
        }
      }

      &--2 {
        & p {
          animation: how-p 17s ease-in-out 5s infinite both;
        }

        & svg {
          animation: how-svg 17s ease-in-out 5s infinite both;
        }
      }

      &--3 {
        & p {
          animation: how-p 17s ease-in-out 8s infinite both;
        }

        & svg {
          animation: how-svg 17s ease-in-out 8s infinite both;
        }
      }

      &--4 {
        & p {
          animation: how-p 17s ease-in-out 11s infinite both;
        }

        & svg {
          animation: how-svg 17s ease-in-out 11s infinite both;
        }
      }

      &--5 {
        & p {
          animation: how-p 17s linear 14s infinite both;
        }

        & svg {
          animation: how-svg 17s linear 14s infinite both;
        }
      }
    }
  }

  &__flavors {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 10px 0;

    &__title {
      width: 100%;
      padding-bottom: 30px;
      font-family: "Roboto", "Oxygen", sans-serif;
      font-size: 1.2rem;
      font-weight: 700;
      text-align: center;
    }

    &__item {
      max-width: 300px;
      padding: 10px 10px;
      line-height: 140%;

      &__figure {
        padding: 20px 0;
        text-align: center;
      }

      &__img {
        width: 150px;
        transition: 0.3s linear;
        &--hidden {
          transform: scaleX(0);
        }
      }

      &__par {
        text-align: center;
        hyphens: auto;

        & span {
          font-size: calc(1rem + 1px);
          color: $olive-drab-3;
          font-weight: 700;
        }
      }
    }
  }
}

@media (min-width: 500px) {
  .health {
    &__flavors {
      &__item {
        width: 50%;
      }
    }
  }
}

@media (min-width: 768px) and (min-height: 768px) {
  .health {
    min-height: calc(100vh - (#{$base-font-size-768} * 0.6 * 2.4));
    &__header {
      padding: 30px 0 20px;

      &__logo__only {
        width: 250px;
      }
    }

    &__animation {
      height: 230px;
      &__frame {
        & svg {
          transform: translateX(-50%) scale(1.5, 1.5);
        }
      }
    }

    &__how-we-do-it {
      &__title {
        padding: 20px 0;
      }

      &__figure {
        padding-top: 30px;
        padding-bottom: 30px;
        text-align: center;
      }

      &__img {
        width: 250px;
      }
    }

    &__flavors {
      padding: 30px 0 20px;

      &__item {
        max-width: 300px;
        padding: 20px 10px;
        line-height: 140%;

        &__figure {
          padding: 30px 0;
          text-align: center;
        }
      }
    }
  }
}
