// COLORS
$yellow-green: rgba(141, 196, 2, 1);
$olive-drab-3: rgba(108, 159, 20, 1);
$fortior-red: rgba(221, 58, 37, 1);
$persimmon: rgba(235, 88, 2, 1);
$orange-web: rgba(250, 161, 7, 1);
$safety-yellow: rgba(255, 206, 18, 1);
$light-yellow: rgba(255, 252, 221, 1);
$clean: rgba(250, 175, 187, 1);
$medical: rgba(0, 137, 165, 1);

$input-background: rgba(255, 255, 255, 0.3);
$placeholder-color: rgb(160, 160, 160);

$base-font-size: 16px;
$base-font-size-768: 19px;

$burger-size: 40px;
$burger-size-1024: 50px;
$header-height: 60px;
$header-height-1024: 70px;
