@import "variables";

.home-page {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  height: calc(100vh - (#{$base-font-size} * 0.6 * 2.4));
  padding: $header-height 10px 10px 10px;

  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  grid-template-areas: "health active" "house prof";

  &__link {
    position: relative;
    width: 100%;
    overflow: hidden;

    transition: 0.5s linear;
    &--hidden {
      opacity: 0;
    }

    &--health {
      grid-area: health;

      &:active {
        .home-page__link__bg--health {
          opacity: 1;
        }
      }
    }

    &--active {
      grid-area: active;

      &:active {
        .home-page__link__bg--active {
          opacity: 1;
        }
      }
    }

    &--house {
      grid-area: house;

      &:active {
        .home-page__link__bg--house {
          opacity: 1;
        }
      }
    }

    &--prof {
      grid-area: prof;

      &:active {
        .home-page__link__bg--prof {
          opacity: 1;
        }
      }
    }

    &__title {
      position: absolute;
      left: 0;
      top: 70%;
      transform: translateY(-50%);
      z-index: 1;

      width: 100%;

      color: #fff;
      font-size: 7vmin;
      text-align: center;

      transition: 0.5s ease-in-out;
    }

    &__bg {
      position: absolute;
      width: 100%;
      height: 100%;

      background-repeat: no-repeat;
      background-size: cover;

      transition: 0.2s linear;
      opacity: 0.6;

      &--health {
        background-image: url(/assets/img/home/home01t.jpg);
        background-position: 50% 15%;
      }

      &--active {
        background-image: url(/assets/img/home/home02t.jpg);
        background-position: 55% 15%;
      }

      &--house {
        background-image: url(/assets/img/home/home03t.jpg);
        background-position: 50% 50%;
      }
      &--prof {
        background-image: url(/assets/img/home/home04t.jpg);
        background-position: 70% 30%;
      }
    }
  }
}

@media (min-width: 768px) and (min-height: 768px) {
  .home-page {
    height: calc(100vh - (#{$base-font-size-768} * 0.6 * 2.4));
  }
}

@media (min-width: 1024px) and (min-height: 1024px) {
  .home-page {
    padding: $header-height-1024 10px 10px 10px;
    &__link {
      &__title {
        font-size: 72px;
      }
    }
  }
}

@media (min-width: 1366px) {
  .home-page {
    &__link {
      &--health {
        &:hover {
          .home-page__link__bg--health {
            opacity: 1;
            transform: scale(1.2, 1.2);
          }
          .home-page__link__title {
            letter-spacing: 8px;
          }
        }
      }

      &--active {
        &:hover {
          .home-page__link__bg--active {
            opacity: 1;
            transform: scale(1.2, 1.2);
          }
          .home-page__link__title {
            letter-spacing: 8px;
          }
        }
      }

      &--house {
        &:hover {
          .home-page__link__bg--house {
            opacity: 1;
            transform: scale(1.2, 1.2);
          }
          .home-page__link__title {
            letter-spacing: 8px;
          }
        }
      }
      &--prof {
        &:hover {
          .home-page__link__bg--prof {
            opacity: 1;
            transform: scale(1.2, 1.2);
          }
          .home-page__link__title {
            letter-spacing: 8px;
          }
        }
      }
    }
  }
}
