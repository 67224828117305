@import "variables";

.navigation {
  position: fixed;
  left: -100vmax;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 97;

  padding-top: $header-height;

  transition: 0.4s cubic-bezier(0.46, 0.03, 0.52, 0.96);

  &-container {
    width: 100%;
    height: 100%;
    transition: 0.3s linear;
    background-color: #000;
    &--white {
      background-color: #fff;
    }

    &--transparent {
      background-color: rgba(0, 0, 0, 0.9);
    }
  }

  &__list {
    width: 100%;
    max-width: 960px;
    height: 100%;
    padding: 10px 0 20px;
    margin: 0 auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }

  &__item {
    width: 80%;

    font-size: 2rem;
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;

    font-family: "Roboto", "Oxygen", sans-serif;
    transition: 0.2s linear;

    &--juice {
      color: $yellow-green;
    }
    &--protein {
      color: $orange-web;
    }
    &--clean {
      color: $clean;
    }
    &--medical {
      color: $medical;
    }
    &--about {
      color: $safety-yellow;
    }
    &--contact {
      color: $fortior-red;
    }

    &.active {
      padding-left: 15px;
      margin-left: -15px;
      border-left: 6px solid $fortior-red;
    }
  }

  &--active {
    left: 0;
  }
}

@media (min-width: 768px) and (min-height: 768px) {
  .navigation {
    &__list {
      height: 60%;
      padding: 30px 0 10px;
    }
  }
}

@media (min-width: 1024px) and (min-height: 1024px) {
  .navigation {
    padding-top: $header-height-1024;
  }
}

@media (min-width: 1366px) {
  .navigation {
    &__link:hover {
      filter: brightness(4);
      letter-spacing: 10px;
    }
  }
}
