.about {
  min-height: calc(100vh - (#{$base-font-size} * 0.6 * 2.4));
  padding: $header-height 20px 20px 20px;

  background-image: url(/assets/img/about/bg-about-d.jpg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: fixed;

  color: #000;

  &-container {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    transition: 0.5s linear;
    &--hidden {
      opacity: 0;
    }
  }

  &__header {
    &__title {
      padding: 20px 0;
      font-family: "Roboto", "Oxygen", sans-serif;
      color: $fortior-red;
      font-size: 1.8rem;
      font-weight: 700;
      text-align: left;
    }
  }

  &__text {
    padding: 10px 10px 0 10px;
    line-height: 140%;

    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
  }

  &__par {
    padding-bottom: 10px;
    text-align: left;
    hyphens: auto;

    & span {
      color: $medical;
      font-weight: 700;
    }
  }
}

@media (min-width: 768px) and (min-height: 768px) {
  .about {
    min-height: calc(100vh - (#{$base-font-size-768} * 0.6 * 2.4));
    &__header {
      &__title {
        padding: 40px 0;
      }
    }
  }
}

@media (min-width: 1024px) and (min-height: 1024px) {
  .about {
    padding: $header-height-1024 20px 20px 20px;
  }
}
