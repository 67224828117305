* {
  box-sizing: border-box;
}

html {
  background-color: #000;
}

:link,
:visited {
  text-decoration: none;
  color: #fff;
}
