@import "variables";

.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: 99;

  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  position: absolute;
  left: calc(50% + 3px - 25px);
  top: calc(50% + 3px - 25px);
  z-index: 2;
  width: 50px;
  height: 50px;
  animation: loader 2s infinite linear;

  &-border {
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    z-index: 1;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border-top: 3px solid $persimmon;
    border-right: 3px solid lighten($persimmon, 40%);
    border-bottom: 3px solid lighten($persimmon, 40%);
    border-left: 3px solid lighten($persimmon, 40%);
    animation: loader-border 0.5s infinite linear;
  }
}
